import {Channel} from "./components/DownloadStepper"
import {Layout} from "../../components/Layout"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {MdTypo} from "../../components/i18n/TransMdTypo"
import {ResponsiveContainer, SectionContainer, SectionTitle} from "../../components/Shared"
import {Card, CardContent} from "@material-ui/core"
import {RecalboxChannelsYaml} from "../../../graphql-types"


const ReleaseNotes = (props: { pageContext: { channel: RecalboxChannelsYaml}}) => {
  const {channel} = props.pageContext
  const {t} = useTranslation()
  return <Layout title={t("download^Release Notes for Recalbox", {version: channel.version})}>
    <ResponsiveContainer>
      <SectionTitle>
        {t("download^Release Notes for Recalbox", {version: channel.version})}
      </SectionTitle>
      <Card>
        <CardContent>
          <MdTypo>
            {channel.releasenotes}
          </MdTypo>
        </CardContent>
      </Card>
    </ResponsiveContainer>
  </Layout>
}

export const ReleaseNotesPage = {
  Page: ReleaseNotes,
  getUrl: (channel: string) => `/download/${channel}/releasenotes/`
}

export default ReleaseNotesPage.Page
